import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'

import SEO from '../components/seo'

const NotFoundPage = () => {
  const [count, setCount] = useState(7)

  useEffect(() => {
    setTimeout(() => {
      setCount((prevState) => prevState - 1)
    }, 1000)
    if (!count) navigate('/')
  }, [count])

  return (
    <>
      <SEO title="Страница не найдена" />
      <div id="main" style={{ height: '100vh', padding: '150px 50px' }}>
        <section id="one">
          <h1>Страница не найдена</h1>
          <p>Вы только что пошли по маршруту, которого не существует...</p>
          <p>
            Эта страница автоматически перенаправляется через {count}. Хотя,
            если вы не хотите ждать, вы можете использовать эту{' '}
            <Link to="/">ссылку</Link>
          </p>
        </section>
      </div>
    </>
  )
}

export default NotFoundPage
